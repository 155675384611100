<template>
	<div class="container">
		<router-view />
	</div>
</template>

<script>
export default {
	name: 'Payments',
};
</script>
<style>
.container {
	flex: 1;
	display: flex;
	flex-direction: column;
}
</style>
